<template>
  <div>
    <div id="teleports"></div>
    <section>
      <CommonTheHeader />
      <slot />
      <CommonTheFooter />
    </section>
      <client-only>
          <CommonCookiePopup />
          <CommonSubscribeModal v-if="!$country.is_india" />
      </client-only>
  </div>
</template>

<script setup>


const { $country } = useNuxtApp()


import { useSeo } from '@/composables/seo/setMetaTags'
const tags = useSeo();
await tags.globalMetatags()




</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  /* filter: blur(1rem); */
}



.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}
.layout-enter-from,
.layout-leave-to {
  filter: grayscale(1);
}

</style>
